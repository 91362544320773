// UserCategory.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { withTranslation } from 'react-i18next';

import { TranslationNamespace } from '../resource/translationNamespace.js';
import { ButtonId } from '../resource/mixpanel.js';
import CategoryIcon from '../container/CategoryIcon.js';
import { LinkWithLanguage as Link } from '../component/LinkWithLanguage.jsx';
import WithIntersectionObserver from '../component/WithIntersectionObserver.jsx';
import HydrationBoundary from '../component/HydrationBoundary.jsx';
import UserFeedItem from '../container/UserFeedItem.js';
import UserHashtagCard from '../container/UserHashtagCard.js';
import {
  InfiniteScroller,
  ScrollItem,
} from '../component/InfiniteScroller.jsx';
import { breakpoint } from '../style/variables.js';
import TextEllipsis from '../style/TextEllipsis.js';
import media from '../style/media.js';
import MoreIconSrc from '../../img/ic-arrow-right-xs.svg';
import { getFeedCategory } from '../resource/feedUtils.js';

const SCROLL_BAR_HEIGHT = 40;
const HASHTAG_CARD_DESKTOP_SIZE = 200;
const HASHTAG_CARD_SIZE = 140;
const ROW_LIMITATION = 2;
const isServer = typeof window === 'undefined';

export class UserCategory extends React.PureComponent {
  state = {
    userIds: [],
  };
  nextTickSetState = null;
  nextTickInit = null;

  constructor(props) {
    super(props);
    const { userIds } = this.props;
    if (isServer || !window.__IS_HYDRATED__) {
      this.state.userIds = userIds;
    }
  }

  fetchFeeds = ({ page }) => {
    const { category, fetchFeeds, isFollowing, shouldRefresh, fetchFollowing } =
      this.props;
    if (isFollowing) {
      return fetchFollowing({ page });
    }
    if (category) {
      return fetchFeeds({
        type: category,
        page,
        shouldSkipLocalCache: shouldRefresh,
      });
    }
  };
  loadMore = () => {
    const { nextPage } = this.props;
    this.fetchFeeds({ page: nextPage });
  };
  initUserCategory = () => {
    const {
      isRemoteConfigMerged,
      category,
      swaggerOnlineFeed,
      subscribeFeedChannel,
    } = this.props;
    if (isRemoteConfigMerged) {
      this.nextTickInit = setTimeout(() => {
        this.fetchFeeds({ page: 1 });
        if (category === swaggerOnlineFeed) {
          subscribeFeedChannel({ feedName: category });
        }
      }, 0);
    }
  };
  componentDidMount() {
    const { isFirstPageFetched } = this.props;
    if (window.__IS_HYDRATED__ || !isFirstPageFetched) {
      this.nextTickSetState = setTimeout(() => {
        const { userIds } = this.props;
        this.setState({ userIds });
      });
    }
    this.initUserCategory();
  }
  componentDidUpdate(prevProps) {
    const { category, isAuthed, isRemoteConfigMerged, shouldRefresh, userIds } =
      this.props;
    if (
      category !== prevProps.category ||
      isAuthed !== prevProps.isAuthed ||
      (isRemoteConfigMerged &&
        isRemoteConfigMerged !== prevProps.isRemoteConfigMerged) ||
      (shouldRefresh && shouldRefresh !== prevProps.shouldRefresh)
    ) {
      this.initUserCategory();
    }

    if (
      userIds !== prevProps.userIds ||
      userIds.length !== prevProps.userIds.length
    ) {
      this.nextTickSetState && clearTimeout(this.nextTickSetState);
      this.nextTickSetState = setTimeout(() => {
        this.setState({ userIds });
      });
    }
  }
  componentWillUnmount() {
    const { unsubscribeFeedChannel, category, swaggerOnlineFeed } = this.props;
    this.nextTickInit && clearTimeout(this.nextTickInit);
    this.nextTickSetState && clearTimeout(this.nextTickSetState);
    if (!window.__IS_HYDRATED__) {
      window.__IS_HYDRATED__ = 'UserCategory';
    }
    if (category === swaggerOnlineFeed) {
      unsubscribeFeedChannel({ feedName: category });
    }
  }

  renderHeader = () => {
    const {
      t,
      category,
      titleI18nId,
      isSquare,
      isLeaderboard,
      isFollowing,
      location,
      index,
    } = this.props;
    const feedName = getFeedCategory({
      category,
      search: location.search,
    });
    return (
      <Header isSquare={isSquare}>
        <Title isSquare={isSquare}>
          {!isSquare && (
            <span
              css={`
                margin-right: 4px;
              `}
            >
              <CategoryIcon category={category} size={20} />
            </span>
          )}
          <TitleText>
            {t(titleI18nId, {
              ns: TranslationNamespace.FEED,
            })}
          </TitleText>
        </Title>
        <More
          $isSquare={isSquare}
          to={
            isFollowing
              ? `/user-following-grid/${feedName}`
              : isLeaderboard
                ? `/user-leaderboard-grid/${feedName}`
                : isSquare
                  ? `/user-feed-grid/${feedName}`
                  : `/user-categories/${category}`
          }
          data-element_id={ButtonId.All.ButtonMore}
          data-tracking_payload={{
            'discover.index': index,
            'discover.category': category,
          }}
        >
          {t('more', {
            ns: TranslationNamespace.GENERAL,
          })}
          <MoreIcon src={MoreIconSrc} aria-hidden />
        </More>
      </Header>
    );
  };

  renderRow = () => {
    const { nextPage, isNextPageFetching, isSquare } = this.props;
    const { userIds } = this.state;
    const shouldHydrate = isServer || !window.__IS_HYDRATED__;

    const renderUserElement = isSquare
      ? shouldHydrate
        ? this.renderHydrateHashtagCard
        : this.renderUserHashtagCard
      : shouldHydrate
        ? this.renderHydrateUser
        : this.renderUser;

    return (
      <ScrollerWrapper>
        <InfiniteScroller
          axis={'x'}
          useWindow={false}
          pageStart={1}
          hasMore={nextPage != null}
          loadMore={this.loadMore}
          isFetching={isNextPageFetching}
          threshold={200}
        >
          <ListWrapper>
            <ListPaddingWrapper isSquare={isSquare}>
              <ScrollItem
                loader={renderUserElement({
                  userId: null,
                  index: null,
                  isRow: true,
                })}
              >
                {userIds.map((userId, index) =>
                  renderUserElement({ userId, index, isRow: true })
                )}
              </ScrollItem>
            </ListPaddingWrapper>
          </ListWrapper>
        </InfiniteScroller>
      </ScrollerWrapper>
    );
  };

  renderGrid = () => {
    const {
      nextPage,
      avatarSize,
      isNextPageFetching,
      isSquare,
      isDoubleRow,
      isLeaderboard,
    } = this.props;
    const { userIds } = this.state;
    const shouldHydrate = isServer || !window.__IS_HYDRATED__;

    const renderUserElement = isSquare
      ? shouldHydrate
        ? this.renderHydrateHashtagCard
        : this.renderUserHashtagCard
      : shouldHydrate
        ? this.renderHydrateUser
        : this.renderUser;
    return (
      <InfiniteScroller
        axis={'y'}
        useWindow={true}
        pageStart={1}
        hasMore={nextPage != null}
        loadMore={this.loadMore}
        isFetching={isNextPageFetching}
        threshold={200}
      >
        <GridListWrapper
          isSquare={isSquare}
          isLeaderboard={isLeaderboard}
          hasRowLimitation={isDoubleRow}
          avatarSize={avatarSize}
        >
          <ScrollItem loader={renderUserElement({ userId: null, index: null })}>
            {userIds.map((userId, index) =>
              renderUserElement({ userId, index })
            )}
          </ScrollItem>
        </GridListWrapper>
      </InfiniteScroller>
    );
  };

  renderHydrateHashtagCard = ({ userId, index, isRow }) => {
    const { category, isLeaderboard } = this.props;
    return (
      <WithIntersectionObserver key={userId || index}>
        {({ isIntersecting }) => (
          <HydrationBoundary
            shouldHydrate={isIntersecting}
            // DON'T add `key` to the following line
            wrapper={<UserHashtagCardWrapper isRow={isRow} />}
          >
            <UserHashtagCard
              userId={userId}
              shouldShowRank={isLeaderboard}
              rank={isLeaderboard ? index + 1 : undefined}
              category={category}
              index={index}
            />
          </HydrationBoundary>
        )}
      </WithIntersectionObserver>
    );
  };

  renderUserHashtagCard = ({ userId, index, isRow }) => {
    const { category, isLeaderboard } = this.props;
    return (
      <WithIntersectionObserver key={userId || index}>
        {({ isIntersecting }) =>
          isIntersecting ? (
            <UserHashtagCardWrapper isRow={isRow}>
              <UserHashtagCard
                userId={userId}
                shouldShowRank={isLeaderboard}
                rank={isLeaderboard ? index + 1 : undefined}
                category={category}
                index={index}
              />
            </UserHashtagCardWrapper>
          ) : (
            <UserHashtagCardWrapper isRow={isRow} />
          )
        }
      </WithIntersectionObserver>
    );
  };

  renderHydrateUser = ({ userId, index }) => {
    const {
      category,
      avatarSize,
      withHalo,
      withChatLink,
      usernameMarginTop,
      marginRight,
    } = this.props;
    return (
      <WithIntersectionObserver key={userId || index}>
        {({ isIntersecting }) => (
          <HydrationBoundary
            shouldHydrate={isIntersecting}
            wrapper={
              <UserWrapper
                key={index}
                avatarSize={avatarSize}
                marginRight={marginRight}
              />
            }
          >
            <UserFeedItem
              id={userId}
              withHalo={withHalo}
              withChatLink={withChatLink}
              categoryIndex={index}
              categoryId={category}
              itemIndexInCategory={index}
              shouldTrackEvent
              size={avatarSize}
              fontSize={12}
              marginTop={usernameMarginTop}
            />
          </HydrationBoundary>
        )}
      </WithIntersectionObserver>
    );
  };

  renderUser = ({ userId, index }) => {
    const {
      category,
      avatarSize,
      withHalo,
      withChatLink,
      usernameMarginTop,
      marginRight,
    } = this.props;
    return (
      <WithIntersectionObserver key={userId || index}>
        {({ isIntersecting }) => (
          <UserWrapper
            key={userId}
            avatarSize={avatarSize}
            marginRight={marginRight}
          >
            <UserFeedItem
              id={isIntersecting ? userId : ''}
              withHalo={withHalo}
              withChatLink={withChatLink}
              categoryIndex={index}
              categoryId={category}
              itemIndexInCategory={index}
              shouldTrackEvent
              size={avatarSize}
              fontSize={12}
              marginTop={usernameMarginTop}
            />
          </UserWrapper>
        )}
      </WithIntersectionObserver>
    );
  };
  render() {
    const {
      meId,
      deviceWidth,
      isFirstPageFetched,
      isSquare,
      isGrid,
      isDoubleRow,
      isLeaderboard,
      shouldDisplayHeader,
      isFollowing,
      style,
    } = this.props;
    const { userIds } = this.state;
    // Default props has 10 mock ids to render placeholder.
    // Hide this category if first page fetched and the list is empty or is mock ids.
    const isMockUserIds = userIds.every(id => !id);
    const isFetched = isFollowing && !meId ? true : isFirstPageFetched;
    const isDesktop = deviceWidth > breakpoint.tablet;

    if (isFetched && (!userIds?.length || isMockUserIds)) {
      return null;
    }

    if (isGrid || (isDoubleRow && isDesktop)) {
      return (
        <StyledUserCategoryGrid style={style}>
          {shouldDisplayHeader ? this.renderHeader() : null}
          {this.renderGrid()}
        </StyledUserCategoryGrid>
      );
    }

    return (
      <StyledUserCategory
        shouldDisplayHeader={shouldDisplayHeader}
        isSquare={isSquare}
        isLeaderboard={isLeaderboard}
        style={style}
      >
        {shouldDisplayHeader ? this.renderHeader() : null}
        {this.renderRow()}
      </StyledUserCategory>
    );
  }
}

UserCategory.propTypes = {
  meId: PropTypes.string,
  category: PropTypes.string,
  titleI18nId: PropTypes.string,
  index: PropTypes.number,
  nextPage: PropTypes.number,
  deviceWidth: PropTypes.number,
  avatarSize: PropTypes.number,
  usernameMarginTop: PropTypes.number,
  marginRight: PropTypes.number,
  isAuthed: PropTypes.bool,
  isSquare: PropTypes.bool,
  isGrid: PropTypes.bool,
  isDoubleRow: PropTypes.bool,
  isLeaderboard: PropTypes.bool,
  isFollowing: PropTypes.bool,
  isNextPageFetching: PropTypes.bool,
  userIds: PropTypes.array,
  fetchFeeds: PropTypes.func,
  fetchFollowing: PropTypes.func,
  shouldRefresh: PropTypes.bool,
  t: PropTypes.func.isRequired,
  isRemoteConfigMerged: PropTypes.bool,
  swaggerOnlineFeed: PropTypes.string,
  subscribeFeedChannel: PropTypes.func,
  unsubscribeFeedChannel: PropTypes.func,
  isFirstPageFetched: PropTypes.bool,
  shouldDisplayHeader: PropTypes.bool,
  withHalo: PropTypes.bool,
  withChatLink: PropTypes.bool,
  style: PropTypes.object,
  location: PropTypes.object,
};

UserCategory.defaultProps = {
  meId: null,
  category: null,
  titleI18nId: null,
  index: null,
  nextPage: null,
  deviceWidth: 0,
  avatarSize: 88,
  usernameMarginTop: 8,
  marginRight: 8,
  isAuthed: false,
  isSquare: false,
  isGrid: false,
  isDoubleRow: false,
  isLeaderboard: false,
  isFollowing: false,
  isNextPageFetching: false,
  userIds: new Array(10).fill(''),
  fetchFeeds: () => null,
  fetchFollowing: () => null,
  shouldRefresh: false,
  isRemoteConfigMerged: false,
  swaggerOnlineFeed: null,
  subscribeFeedChannel: () => null,
  unsubscribeFeedChannel: () => null,
  isFirstPageFetched: false,
  shouldDisplayHeader: true,
  withHalo: false,
  withChatLink: false,
  style: {},
  location: {},
};

const StyledUserCategory = styled.div`
  ${({ isSquare, isLeaderboard }) => {
    if (isSquare) {
      return css`
        height: ${316 + (isLeaderboard ? 12 : 0)}px;
        ${media.tablet`
          height: ${256 + (isLeaderboard ? 12 : 0)}px;
        `}
        ${media.mobile`
          height: ${244 + (isLeaderboard ? 13 : 0)}px;
        `}
        overflow-y: hidden;
      `;
    }
    return css`
      height: ${({ shouldDisplayHeader }) =>
        shouldDisplayHeader ? 180 : 146}px;
      overflow-y: hidden;
    `;
  }}
`;

const StyledUserCategoryGrid = styled.div``;

const Header = styled.div`
  ${({ isSquare }) => {
    if (isSquare) {
      return css`
        padding: 0 16px 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      `;
    }
    return css`
      margin-bottom: 4px;
      margin-top: 0;
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    `;
  }}
`;

const Title = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 150%;
  ${media.mobile`
    font-size: 18px;
  `}
`;

const TitleText = styled.div`
  ${TextEllipsis};
`;

const More = styled(Link)`
  flex: none;
  font-weight: 600;
  display: flex;
  align-items: center;
  font-size: 14px;
  letter-spacing: 0.014px;
  line-height: 150%;
  ${media.mobile`
    font-size: 12px;
  `}
`;

const MoreIcon = styled.img`
  margin-left: 4px;
  height: 16px;
  width: auto;
`;

const ScrollerWrapper = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  font-size: 0px;
  &::-webkit-scrollbar {
    display: none;
  }
`;

const ListWrapper = styled.div`
  display: inline-block;
`;

const GridListWrapper = styled.div`
  padding-left: 16px;
  padding-right: 16px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(104px, 104px));
  grid-column-gap: 8px;
  grid-row-gap: 20px;
  justify-content: center;
  ${({ isSquare, hasRowLimitation }) =>
    isSquare
      ? css`
          grid-template-columns: repeat(
            auto-fill,
            minmax(
              ${HASHTAG_CARD_DESKTOP_SIZE}px,
              ${hasRowLimitation ? `${HASHTAG_CARD_DESKTOP_SIZE}px` : '1fr'}
            )
          );
          grid-column-gap: 8px;
          grid-row-gap: 8px;
          ${media.tablet`
            grid-template-columns: repeat(
              auto-fill,
              minmax(
                ${HASHTAG_CARD_SIZE}px,
                ${hasRowLimitation ? `${HASHTAG_CARD_SIZE}px` : '1fr'}
              )
            );
          `};
        `
      : css``}
  ${({ isSquare, isLeaderboard, hasRowLimitation, avatarSize }) =>
    hasRowLimitation
      ? isSquare
        ? css`
            max-height: ${(HASHTAG_CARD_DESKTOP_SIZE +
              4 +
              (isLeaderboard ? 33 : 24) +
              8 +
              28 +
              8) *
              2 +
            8}px; // item height = card size 200px + padding top 4px + username 24 or 33px + gap 8px + hashtags 28px + padding bottom 8px
            overflow: hidden;
            ${media.tablet`
              max-height: ${
                (HASHTAG_CARD_SIZE +
                  4 +
                  (isLeaderboard ? 33 : 21) +
                  8 +
                  22 +
                  8) *
                  2 +
                8
              }px; // item height = card size 140px + padding top 4px + username 21 or 33px + gap 8px + hashtags 22px + padding bottom 8px
            `};
          `
        : css`
            max-height: ${(avatarSize + 16 + 16 + 6) * ROW_LIMITATION +
            20}px; // item height = padding top 6px + avatar 88px + gap 16px + username 16px
            overflow: hidden;
          `
      : css``}
`;

const ListPaddingWrapper = styled.div`
  padding-right: ${({ isSquare }) => (isSquare ? 16 : 10)}px;
  padding-left: ${({ isSquare }) => (isSquare ? 16 : 10)}px;
  padding-bottom: ${SCROLL_BAR_HEIGHT}px;
  display: flex;
`;

const UserWrapper = styled.div.attrs(({ avatarSize }) => ({
  style: {
    width: `${avatarSize + 12}px`,
  },
}))`
  margin-right: ${({ marginRight }) => `${marginRight}px`};
  padding-top: 6px;
  overflow: hidden;
  :last-child {
    margin-right: 0px;
  }
`;

const UserHashtagCardWrapper = styled.div`
  ${({ isRow }) => {
    if (isRow) {
      return css`
        width: ${HASHTAG_CARD_DESKTOP_SIZE}px;
        margin-right: 8px;
        ${media.tablet`
          width: ${HASHTAG_CARD_SIZE}px
        `}
      `;
    }
  }}
`;

export default withTranslation()(UserCategory);
